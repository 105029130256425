import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// fontawesome関連
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import { fab } from '@fortawesome/free-brands-svg-icons'; //fontawesomeのbrandアイコンのインポート
import { fas } from '@fortawesome/free-solid-svg-icons'; //fontawesomeのsolidアイコンのインポート
import { far } from '@fortawesome/free-regular-svg-icons'; //fontawesomeのregularアイコンのインポート
library.add(fab, fas, far);
//メディアクエリ関連
import React, { Fragment } from 'react';
import Media from 'react-media';
// 認証
import { AuthProvider } from '@/context/AuthContext';

const GlobalStyle = createGlobalStyle`
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-size: 10px;
}

* {
  box-sizing: border-box;
}
`;

const theme = {
  colors: {
    primary: '#0070f3',
  },
};

function App({ Component, pageProps, router }) {
  const router2 = useRouter();

  const [isIe, setIsIE] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toUpperCase();

    if (userAgent.indexOf('MSIE') === -1 && userAgent.indexOf('TRIDENT') === -1) {
      // IE以外のブラウザでアクセス
      setIsIE(false);
    } else {
      // IEでアクセス
      setIsIE(true);
    }
  }, [router.pathname]);
  if (isIe)
    return <p>IEには対応していません。Edge, chrome等の最新のブラウザを使用してください。</p>;

  return (
    <>
      <AuthProvider>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Media
            queries={{
              medium: '(min-width: 768px)',
            }}
          >
            {(matches) => (
              <Fragment>
                <Component {...pageProps} />
              </Fragment>
            )}
          </Media>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;
